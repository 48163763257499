import React, { useState, useEffect, useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import axios from 'axios';
import { quoteContext } from '../../../provider';

import '../../assets/styles/pages/quote/confirmation.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const QuoteConfirmation = () => {
  const data = useStaticQuery(graphql`
    query {
      verifyEmail: file(relativePath: { eq: "quote/mail@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 244) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      general {
        email
        phone
      }
    }
  `);
  const { general } = data;
  const [userEmail, setUserEmail] = useState('');
  const [bookingRef, setBookingRef] = useState('');
  const [resendEmailMsg, setResendEmailMsg] = useState('');
  const [verification, setVerification] = useState(0);
  const [resendEmailSuccess, setResendEmailSuccess] = useState('');
  let { quoteForm, clearQuoteForm } = useContext(quoteContext);

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0) {
      if (userEmail === '') {
        setUserEmail(quoteForm.email);
        setBookingRef(quoteForm.bookingRef);
        setVerification(quoteForm.verified);
        clearQuoteForm({});
      }
    } else {
      navigate('/quote');
      return;
    }
  }, []);

  const reSendVerificationEmail = () => {
    axios.post(`${process.env.GATSBY_PORT}endpoints/sendverificationemail`, { 'bookingRef': bookingRef })
      .then(response => {
        const { data } = response;
        let msg = 'There has been an issue sending your verification code. Please contact support.';
        if (data.success) {
          msg = 'Your verification email has been resent, please check your email now.';
        }
        setResendEmailSuccess(data.success);
        setResendEmailMsg(msg);
      }).catch(function (error) {
        console.warn(error)
      });
  };

  if (verification) {
    return (
      <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
        <SEO title="Quote Confirmation" />
        <div className="quote-wrapper confirmation">
          <div className="container sm">
            <div className="booking-reference">
              Booking Reference <span>{bookingRef}</span>
            </div>
            <div className="verify-email-image icon-check-circle-o"></div>
            <h1>Thank You!</h1>
            <div className="text-wrapper">
              <p>
                With these details and images we aim to get quotes back within 48 hours so you can then select and approve the best quote for your car.
              </p>
              <p>
                If we have any more questions to help with this repair we will contact you otherwise we will notify you once your submission has been approved.
              </p>
            </div>
  
            <span className="need-help">Need help? Call us on <a href={`tel:${general.phone}`}>{general.phone}</a> or email at <a href={`mailto:${general.email}`}>{general.email}</a></span>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <SEO title="Quote Confirmation" />
      <div className="quote-wrapper confirmation">
        <div className="container sm">
          <div className="booking-reference">
            Booking Reference <span>{bookingRef}</span>
          </div>
          <div className="verify-email-image">
            <Img fluid={data.verifyEmail.childImageSharp.fluid} />
          </div>
          <h1>Please verify your email.</h1>
          <div className="text-wrapper">
            <p>
              We need to verify your email in order to receive your quotes.<br />
                    An email was sent to <strong>{userEmail}</strong> with a link to verify your submission.
            </p>
            <p>
              Leave this page open while you check your email, if you have not received the email after a few minutes, please check your spam folder.
            </p>
            <p>
              Still can’t find the email?
            </p>
          </div>
          {resendEmailMsg === '' && (
            <span className="btn" onClick={reSendVerificationEmail}>
              Resend email
            </span>
          )}
          {resendEmailMsg && (
            <p className={resendEmailSuccess ? '' : 'error'}>{resendEmailMsg}</p>
          )}

          <span className="need-help">Need help? Call us on <a href={`tel:${general.phone}`}>{general.phone}</a> or email at <a href={`mailto:${general.email}`}>{general.email}</a></span>
        </div>
      </div>
    </Layout>
  );
};

export default QuoteConfirmation;
